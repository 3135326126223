.Companies {
  // background: white;
  padding-top: 60px;
  padding-bottom: 80px;

  .company {
    margin-bottom: 2rem;
  }

  .company-card {
    padding: 30px 30px 30px 0px;
    // box-shadow: 0 8px 16px 0 rgba(0,0,0,.1);
    border-radius: 4px;
  }

  .logo {
    display: inline-block;
    padding: 8px;
    border-radius: 4px;
    opacity: 1;
    transition: opacity ease-in-out 0.15s;
    width: 140px;
    height: 140px;
    margin: auto;

    @media screen and (max-width: 768px) {
      height: 80px;
      width: 100%;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}