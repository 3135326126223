$primary: #652f8f;
$secondary: #272D2D;

@import "node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6, .btn {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
}

h6 {
  position: relative;
  // display: flex;
  text-align: center;
  color: var(--gray-dark);
}

h1 {
  position: relative;
  // display: flex;
  text-align: center;
  font-size: 2.25rem;
  color: var(--gray-dark);

  // &:before {
  //   content: '';
  //   position: relative;
  //   flex-grow: 1;
  //   height: 1px;
  //   top: 24px;
  //   width: 100px;
  //   background: black;
  //   margin-right: 40px;
  // }

  // &:after {
  //   content: '';
  //   position: relative;
  //   flex-grow: 1;
  //   height: 1px;
  //   top: 24px;
  //   width: 100px;
  //   background: black;
  //   margin-left: 40px;
  // }
}

a {
  color: var(--secondary);
}

label {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-dark);
}

.hide-mobile {
  @media screen and (max-width: 768px) {
    height: 0px;
    width: 0%;
    visibility: hidden;
    flex: none;
    position: absolute;
    z-index: -1000;
  }
}

.modal-content {
  border-radius: 0px;
}

.modal-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   display: block;
  //   height: 36px;
  //   width: 36px;
  //   border-bottom: 4px solid black;
  //   border-left: 4px solid black;
  //   bottom: 0;
  //   left: 0;
  // }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   display: block;
  //   height: 36px;
  //   width: 36px;
  //   border-top: 4px solid black;
  //   border-left: 4px solid black;
  //   top: 0;
  //   left: 0;
  // }
}

.modal-header {
  border-left: 0.5rem solid var(--secondary);
  border-radius: 0px;
}