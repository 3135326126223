.Navigation {
  .logotype {
    width: 290px;
    height: 48px;
    object-fit: contain;
  }
  
  .desktop-nav {
    padding-bottom: 4px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: white;
    padding: 24px 0px;

    @media screen and (max-width: 768px) {
      display: none;
      height: none;
    }

    .btn-link {
      padding: 12px 1rem;
      text-decoration: none;
      border-radius: 0px;
      color: var(--gray-darker);
      font-size: 18px;
      font-weight: 500;

      &:hover {
        color: var(--primary);
      }
    }
  }

  .mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: white;
    z-index: 100;
    border-bottom: 1px solid #eee;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);

    .navbar {
      background: white;
    }

    .nav-link {
      color: var(--gray-darker);
    }

    @media screen and (min-width: 767px) {
      display: none;
      height: none;
    }
  }
}

.scroll-nav {
  text-align: right;
  position: fixed;
  top: 30px;
  right: 0px;
  z-index: 1000;
  opacity: 0.98 !important;
  animation: fadeIn 400ms ease-in-out;
  visibility: visible;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0.98;
    transform: translateX(0px);
  }
}

.hidden-nav {
  position: fixed;
  top: 30px;
  right: -350px;
  z-index: 1000;
  animation: fadeOut 400ms ease-in-out;
  text-align: right;
}

.hidden-nav-no-animation {
  position: fixed;
  top: 30px;
  right: -350px;
  z-index: 1000;
  text-align: right;
}

@keyframes fadeOut {
  0% {
    opacity: 0.98;
    right: 0px;
  }
  100% {
    opacity: 0;
    right: -150px;
  }
}

.side-nav-btn {
  font-weight: 500;
  margin-right: 15px;
  margin-bottom: 24px;

  a {
    text-decoration: none !important;
  }

  &:hover {
    color: var(--primary);
  }

  &.active {
    padding-right: 11px;
    border-right: 4px solid var(--primary);
    margin-right: 0px;

    a {
      color: var(--primary);
    }
  }
}